import { Icon } from '@iconify/react'
import { GetServerSideProps } from 'next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { useEffect } from 'react'

import { supabase } from '~/utils/supabase'
import { useUser } from '~/utils/UserContext'

const Page = () => {
  const user = useUser()
  return <>{!user ? <Login /> : <Redirecting />}</>
}
export default Page

export const Redirecting = () => {
  const user = useUser()
  const router = useRouter()

  useEffect(() => {
    // サーバー側では失効しているがローカルではuserが生きている
    if (user) {
      const session = supabase.auth.session()
      const reloadCookie = async () => {
        await fetch('/api/auth', {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          credentials: 'same-origin',
          body: JSON.stringify({ event: 'SIGNED_IN', session }),
        })
        router.reload()
      }
      reloadCookie()
    }
  }, [user])

  return (
    <>
      <NextSeo title="Log in" />
      <div className="w-full min-h-screen bg-[url('/bg.jpg')] bg-cover">
        <div className="grid place-items-center px-8 w-full min-h-screen backdrop-brightness-50">
          <div>{/* placeholder */}</div>
          <div className="mx-auto text-sm text-white opacity-70">
            少々お待ちください・・・
          </div>
          <div
            className="text-xs text-white underline opacity-70 cursor-pointer"
            onClick={() => supabase.auth.signOut()}
          >
            読み込まれない場合はこちらをクリック
          </div>
        </div>
      </div>
    </>
  )
}

const Login = () => {
  return (
    <>
      <div className="w-full min-h-screen bg-[url('/bg.jpg')] bg-cover">
        <div className="grid place-items-center px-8 w-full min-h-screen backdrop-brightness-50">
          <div className="py-20 w-full max-w-lg bg-gray-100 rounded-xl shadow-2xl">
            <div className="flex flex-col gap-5">
              <div className="space-y-2">
                <h2 className="text-xl font-bold leading-9 text-center text-gray-700 uppercase">
                  Log in to
                </h2>
                <div className="relative h-12">
                  <Image
                    src="/logo.png"
                    layout="fill"
                    objectFit="contain"
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              </div>

              <div className="px-8 mx-auto mt-8 w-full max-w-md">
                <div className="flex flex-col gap-5 w-full">
                  <div className="w-full">
                    <div
                      onClick={() =>
                        supabase.auth.signIn(
                          {
                            provider: 'slack',
                          },
                          {
                            // 末尾スラッシュの有無に注意。supabaseの設定画面とと完全一致しないといけない
                            redirectTo:
                              process.env.NEXT_PUBLIC_REDIRECT_URL_AFTER_LOGIN,
                          }
                        )
                      }
                    >
                      <Button />
                    </div>
                  </div>
                  <div className="text-xs">
                    <p>
                      もしボタンを押した後のページでエラーになる場合は、まず
                      <br />
                      <a
                        href={`https://${process.env.NEXT_PUBLIC_SLACK_TEAM_NAME}.slack.com/`}
                        className="font-bold underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Slackワークスペースへのログイン
                      </a>
                      をしてから、再度試してください。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Button = () => {
  return (
    <>
      <span className="inline-flex w-full rounded-md shadow-sm">
        <button
          type="button"
          className="inline-flex justify-center items-center py-2 px-4 w-full text-sm font-medium leading-5 text-white bg-gray-900 rounded-xl border-[2px] border-gray-100 hover:opacity-75 transition duration-150 ease-in-out"
        >
          <>
            <Icon icon="logos:slack-icon" className="w-6 h-6" />
          </>
          <span className="mr-1 ml-2">Slack</span>
          <span>でログイン</span>
        </button>
      </span>
    </>
  )
}

// ログイン後、UserContextがブラウザをリロードするので、そのときにこれが発動する
export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const authResult = await supabase.auth.api.getUserByCookie(req)

  if (authResult?.error?.status === 401) {
    // Invalid token: token is expired by〜の場合がある
    // console.log(authResult.error.message)
    supabase.auth.refreshSession()
  }

  // userがいればリダイレクト、なければこのページを表示
  if (authResult?.user) {
    return {
      redirect: {
        permanent: false,
        destination: '/app',
      },
    }
  } else {
    return { props: {} }
  }
}
